import React from 'react';
import {Link, graphql} from 'gatsby';

import SharedStyles from './shared-styles.js';
import HeaderSVG from './header-svg.js';
import PostListItem from './post-list-item.js';

export default ({data}) => (
    <div style={SharedStyles.container}>
        <header style={SharedStyles.header}>
            <HeaderSVG top={-500} />
            <div style={SharedStyles.headerBody}>
                <h1 style={SharedStyles.h1}>Arguing with Algorithms</h1>
                <p style={SharedStyles.p}>
                    This is the blog of <Link to="/">Tom Yedwab</Link>.
                </p>
                <p style={SharedStyles.p}>
                    I blog about engineering, engineering management, data science, and procrastination, the things I know best.
                </p>
            </div>
        </header>

        <article style={SharedStyles.article(true)}>
            <h1 style={SharedStyles.h1}>Latest Post</h1>
            <PostListItem post={data.allMarkdownRemark.edges[0].node} />
        </article>

        <article style={SharedStyles.article(false)}>
            <h1 style={SharedStyles.h1}>Archive</h1>

            <div style={styles.postListWrapper}>
                {data.allMarkdownRemark.edges.slice(1).map(post => <PostListItem post={post.node} size="small" />)}
            </div>
        </article>

        <footer style={SharedStyles.footer}>
            ~ &copy; 2019 Tom Yedwab ~
        </footer>
    </div>
);

const styles = {
    postListWrapper: {
        width: 1100,
        margin: "64px auto",
    },
};

export const query = graphql`
query {
    allMarkdownRemark(
        sort: {
            fields: [frontmatter___sortdate]
            order: DESC
        }
    ) {
        edges {
            node {
                fileAbsolutePath
                frontmatter {
                    title
                    dateline
                    sortdate
                }
                excerpt
                html
            }
        }
    }
}`;
